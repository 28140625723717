import type { RequestParams } from '../interfaces/RequestParams'
import { apiBaseURL, workflowServiceUrl } from '@/config/app.config';

export const getRequestParams = (): RequestParams => {

  return {
    apiV1: {
      url: '/api/v1',
    },
    apiV12: {
      url: '/api/v2',
    },
    apiV2: {
      url: apiBaseURL || '',
    },
    workflow: {
      url: workflowServiceUrl || '',
    },
  };
};

//VITE_APP_API_V2_BASE_URL
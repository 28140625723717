import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const dateTimeFormat = (date) => {
  return dayjs.utc(date).format("DD/MMM/YYYY HH:mm");
};

export default dateTimeFormat;

import Vue from 'vue'
import App from './App.vue'
import './ui-lib/directives'
import vuetify from './plugins/vuetify'
import router from '@/plugins/router'
import store from '@/plugins/store/index'
import axios from '@/plugins/axios'
import i18n from '@/plugins/i18n'
import '@/plugins/vMask';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import Hotjar from '@hotjar/browser';
import mixpanel from 'mixpanel-browser';
import { currencyFormat, dateFormat, dateTimeFormat } from "@/shared/filters";
import { hotjarSiteId, mixpanelSiteId } from "@/config/app.config";
import VueLuzmoDashboard from "@luzmo/vue-embed/vue2";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
axios.init();

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.locale("es");
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$filters = {
  currencyFormat,
  dateFormat,
  dateTimeFormat,
};

const siteId = hotjarSiteId;
const hotjarVersion = hotjarSiteId;
if (hotjarSiteId)
    Hotjar.init(siteId, hotjarVersion);
Vue.prototype.Hotjar = Hotjar;

const mixpanelID = mixpanelSiteId;
if (mixpanelID)
    mixpanel.init(mixpanelID, {track_pageview: true});
Vue.prototype.mixpanel = mixpanel;
Vue.use(VueLuzmoDashboard);

new Vue({
    vuetify, router, store, i18n,
    render: h => h(App)
}).$mount('#app')
